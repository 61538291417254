html, body {

	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: Georgia, serif;
}

#root {

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}