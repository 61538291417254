.WallOfCards {

	position: absolute;
	left: 0;
	top: 50px;
	width: 100%;
	height: calc(100% - 50px);
}

.noPointerEvent {

	pointer-events: none;
}

.card {

    position: absolute;
    opacity: 1;
}

.card:hover {

    cursor: pointer;
}

.cardVisible {

    pointer-events:auto;
}

.cardShadowPath {

    width: 100%;
    height: 100%;
    background-color: rgba(30, 30, 30, 0.7);
    clip-path: polygon(2% 80%, 3% 98%, 50% 89%, 100% 100%, 95% 2%);
    -webkit-clip-path: polygon(2% 80%, 3% 98%, 50% 89%, 100% 100%, 95% 2%);
}

.cardShadows {

	width: 105%;
	height: 125%;
    filter: drop-shadow(10px 15px 7px rgba(0, 0, 0,0.8)) blur(7px);
}

.cardShadowsFar {

    opacity: 0;
}

.cardShadowsNear {

    opacity: 1;
}

.cardShadowsFarNear {

    transition: opacity 0.4s 0.6s;
    -webkit-transition: opacity 0.4s 0.6s;
    -o-transition: opacity 0.4s 0.6s;
    -moz-transition: opacity 0.4s 0.6s;
}

.cardShadowsNearFar {

    transition: opacity 0.2s;
    -webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
}

.cardAnimated {

    transition: transform 1s;
    -webkit-transition: -webkit-transform 1s;
    -o-transition: -o-transform 1s;
    -moz-transition: -moz-transform 1s;
}

.cardShadowAnimated {

    transition: transform 1s, opacity 1s;
    -webkit-transition: -webkit-transform 1s, opacity 1s;
    -o-transition: -o-transform 1s, opacity 1s;
    -moz-transition: -moz-transform 1s, opacity 1s;
}

.cardBackground {

    background-color: white;
    position: absolute;
    left: 0px;
    top: 0px;
}

.cardBackground > img {

    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
}

.cardBackground > video {

    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
}

.cardBackground > div {

    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
}

.cardBackground > iframe {

    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    border: none;
    pointer-events: none;
    -ms-touch-action: none; 
    touch-action: none; 
    -webkit-touch-callout: none;
}

.cardTitle {

    font-family: "Courier New";
    font-weight: bold;
    font-size: 12px;
    margin-left: 5px;
    height: 15px;
    white-space: nowrap;
    overflow: hidden;
}

.fullscreen {

    background-image: url("fullscreen.png");
    background-size: contain;
    position: absolute;
    bottom: 20px;
    right: 10px;
    width: 64px;
    height: 36px;
    pointer-events: initial;
}

.fullscreen:hover {

    background-image: url("fullscreen_white.png");
    cursor: pointer;
}

.fullscreenStep0 {


}

.fullscreenStep1 {


}

.fullscreenStep2 {

    background-image: url("fullscreen_white.png");
}

.fullscreenStep3 {


}

.fullscreenTip {

    position: absolute;
    bottom: 70px;
    width: 140px;
    height: 80px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    transition: right 1s ease-in-out;
    -webkit-transition: right 1s ease-in-out;
    -o-transition: right 1s ease-in-out;
    -moz-transition: right 1s ease-in-out;
}

.fullscreenTipStep0 {

    right: -200px;
}

.fullscreenTipStep1 {

    right: 10px;
}

.fullscreenTipStep2 {

    right: 10px;
}

.fullscreenTipStep3 {

    right: 10px;
}

.fullscreenTipStep4 {

    right: -200px;
}

.fullscreenTip > span {

    margin: auto;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    color: red;
}

.CardAnimated {

    transition: top 1s, left 1s;
}

.TopLeft {

    top: -300px !important;
    left: -300px !important;
}

.TopRight {

    top: -300px !important;
    left: calc(100% + 300px) !important;
}

.BottomLeft {

    top: calc(100% + 300px) !important;
    left: -300px !important;
}

.BottomRight {

    top: calc(100% + 300px) !important;
    left: calc(100% + 300px) !important;
}

