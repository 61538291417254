.Frame {

	position: absolute;
	top: 50px;
	left: 0;
	width: 100vw;
	height: calc(100vh - 50px);
	background-color: rgba(238,238,238,0.5);
	backdrop-filter: blur(5px);
	display: flex;
}

.FrameContainer {

	height: 100%;
	border: none;
	background-color: white;
	margin: auto;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
    overflow: hidden;
}

.FrameContainerClosed {

	width: 0%;
}

.FrameContainerOpened {

	width: 70%;
}

.CloseFrame {

	width: 40px;
	height: 40px;
	position: absolute;
	left: calc(15% - 40px);
	background-image: url("close.svg");
	background-size: contain;
}

.FrameContainer > iframe {

	width: 100%;
	height: 100%;
	border: none;
	background-color: white;
	margin: auto;
}

.FrameHidden {

	display: none;
}

.ContactForm {

	margin: 20px;
}

.ContactTitle {

	font-weight: 800;
	font-size: 34px;
	text-align: center;
	margin-bottom: 50px;
}

.ContactForm > input {

  display: block;
  width: calc(70%);
  margin: 10px;
  font-size: 18px;
  border: 1px lightgray solid;
}

.ContactForm > input:focus {

  outline: none !important;
}

.ContactForm > textarea {

  width: calc(70%);
  margin: 10px;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  resize: none;
  border: 1px lightgray solid;
}

.ContactForm > textarea:focus {

  outline: none !important;
}

.SendMessage {

  margin: 10px;
  width: 64px;
  height: 56px;
  background-image: url("plane.svg");
  background-size: contain;
}

.SendMessage:hover {

  cursor: pointer;
  background-image: url("plane_focused.svg");
}

@media (max-width: 700px) {

	.CloseFrame {

		left: 0;
	}

	.FrameContainerOpened {

		width: 100%;
	}

	.ContactForm > input {

	  	width: calc(100% - 40px);
	}

	.ContactForm > textarea {

  		width: calc(100% - 40px);
  	}
}