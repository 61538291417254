.Header {

	width: 100%;
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: rgba(238,238,238);
}

.title {

	height: 100%;
	display: flex;
	align-items: center;
	font-size: 24px;
	flex: 2;
}

.meandmaps {
	font-weight: 800;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
}

.logo {

	margin-left: 10px;
	width: 50px;
	height: 50px;
	background-image: url("logo.png");
	background-size: contain;
}

.logo:hover {

	cursor: pointer;
}

.title > span {

	margin-left: 20px;
	cursor: default;
}

.menu {

	list-style-type: none;
	margin: 0 10px;
	padding: 0;
	z-index: 10;
}

.menu > li {

    text-decoration: none;
    float: left;
    font-family: Georgia, serif;
	font-size: 20px;
	margin: 0 10px;
}

.menu > li:hover {

	cursor: pointer;
	text-decoration: underline;
}

.openMenu {

	width: 30px;
	height: 30px;
	background-image: url("menu.svg");
	background-size: contain;
	display: none;
	float: right;
}

#twitter {

	width: 32px;
	height: 32px;
	cursor: pointer;
	background-image: url("twitter.png");
	background-size: contain;
	border: none;
}

#twitter:hover {

	background-image: url("twitter_blue.png");
}

@media (max-width: 1000px) {

  .title {
  	font-size: 20px;
  }

  .menu > li {

  	font-size: 18px;
  }
}

@media (max-width: 900px) {
	
  .subtitle {
    display: none;
  }

}

@media (max-width: 700px) {

	.menu {
    	background-color: rgba(238,238,238,0.9);
		backdrop-filter: blur(5px);
    	border-radius: 5px;
    	align-self: baseline;
    }

	.menu > li {

		float: unset;
		margin: 10px;
	}

	.openMenu {

		display: block;
	}

	.itemMenu {

		display: none;
	}

}
